import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm"
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import { Suspense } from "react";
import { MARKDOWN_PARSER_COMPONENTS_CUSTOMIZATION } from "./constant";

const MARKDOWN_CLASSNAME_HELPER = `
w-full prose
prose-a:no-underline prose-a:text-primary-300 hover:prose-a:underline
prose-p:mt-0 prose-p:mb-1
prose-li:mt-0 prose-li:mb-1
prose-ul:mt-0 prose-ul:mb-2
prose-ol:mt-0 prose-ol:mb-2
`

export default function MarkdownParser({ content }) {
  return (
    <Suspense fallback={<>Loading...</>}>
      <section className={MARKDOWN_CLASSNAME_HELPER}>
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw, rehypeSanitize]}
          components={MARKDOWN_PARSER_COMPONENTS_CUSTOMIZATION}>
          {content}
        </ReactMarkdown>
      </section>
    </Suspense>
  )
}
