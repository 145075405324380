import { Footer, Header, Navbar, BeasiswaCard } from "../components";
import { beasiswa } from "../constants";
import { useTranslation, Trans } from "react-i18next";

const BeasiswaPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      <Header title={t("scholarship.header")}/>
      <main className="flex flex-wrap items-center justify-center gap-3 py-10 px-36">
        <div className="container flex flex-wrap items-center justify-center max-w-fill gap-x-5 gap-y-16">
        {beasiswa.map((bea, index) => {
            return (
              <BeasiswaCard
              key={bea.title}
              index={index} 
              {...bea}
            />
            );
          })}
        </div>
      </main>
      <Footer />
    </>
  );
};

export default BeasiswaPage;
