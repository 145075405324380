import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import { bgheader, olheader } from "../../assets";
import { styles } from "../../styles";
import React from "react";

const Header = ({ title }) => {
  const location = useLocation();
  const { pathname } = location;
  const path = pathname.split("/").filter((page) => page !== "");

  const { t } = useTranslation();

  const crumbs = (
    <div className={`${styles.textLRegularM} flex items-center gap-2`}>
      <Link to="/" className="hover:underline">
        {t("header.home")}
      </Link>
      <FaChevronRight className="w-2" />
      {path.map((page, index) => {
        return (
          <React.Fragment key={index}>
            <p>{page}</p>
            <FaChevronRight
              className={`w-2 ${index === path.length - 1 && "hidden"}`}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
  return (
    <div
      style={{
        background: `linear-gradient(to bottom, #920E3450, #920E3475), url(${bgheader})center/cover no-repeat`,
      }}
      className={`${styles.paddingX} w-full h-[300px] relative flex items-center text-white sm:h-40`}
    >
      <img
        src={olheader}
        alt="overlay"
        className="absolute left-0 smd:hidden"
      />
      <img
        src={olheader}
        alt="overlay"
        className="absolute right-0 scale-x-[-1] smd:hidden"
      />
      <div className="flex flex-col gap-2">
        {crumbs}
        <h1 className={`${styles.headingLM}`}>{title}</h1>
      </div>
    </div>
  );
};

export default Header;
