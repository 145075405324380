import { PiUserBold, PiCalendarBlankBold } from "react-icons/pi";
import { useParams, Navigate, useLocation, Link  } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { Footer, MarkdownParser, Navbar } from "../components";
// import { articles } from "../constants";
import { styles } from "../styles";
import customAxios from "../utils/customAxios";
import LoadingPage from "./LoadingPage";
import { formatDate } from "../utils/helper";

const ArtikelTextPage = () => {
  // const { i18n } = useTranslation();
  // const currentLanguage = i18n.language;
  const location = useLocation();
  const { slug } = useParams();

  const getArticle = async () => {
    return await customAxios.get(`articles/${slug}`);
  }

  const {
    data: articlesData,
    isFetching,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["data"],
    queryFn: () => getArticle(),
    placeholderData: keepPreviousData, // don't have 0 rows flash while changing pages/loading next page
    refetchOnWindowFocus: false,
  });

  if (isLoading || isFetching) return <LoadingPage />;

  if (isError) return <Navigate to="/error"></Navigate>

  const { data } = articlesData.data;

  const { pathname } = location;
  const path = pathname.split("/").filter((page) => page !== "");

  console.log(path);

  const Crumbs = (
    <div
      className={`${styles.textLRegularM} flex items-center gap-2 w-full text-neutral-80`}
    >
      <Link to="/" className="hover:underline">
        beranda
      </Link>
      <FaChevronRight className="w-2" />
      <Link to="/artikel" className="hover:underline">
        artikel
      </Link>
      <FaChevronRight className="w-2" />
      <p className="line-clamp-1">{slug}</p>
    </div>
  );

  const { title, description, created_at, thumbnail } = data

  return (
    <>
      <Navbar />
      <div
        className={`${styles.paddingArticle} gap-4 flex flex-wrap justify-center py-10`}
      >
        <div className="max-w-[858px]">
          {Crumbs}

          <div>
            <h1 className={`text-[36px] leading-11 text-neutral-100 font-bold`}>
              {title}
            </h1>

            <div className="flex items-center mt-3">
              <PiUserBold className="fill-neutral-80" />
              <p className={`${styles.textLRegular} text-neutral-80 mx-2`}>
                Admin UNIVERAL
              </p>
              <PiCalendarBlankBold className="ml-5 fill-neutral-80" />
              <p className={`${styles.textLRegular} text-neutral-80 mx-2`}>
                {formatDate(created_at)}
              </p>
            </div>

            <img className={`my-8 w-full h-full object-cover`} src={thumbnail} alt={title} />

            <MarkdownParser content={description} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArtikelTextPage;
