import { useTranslation, Trans } from "react-i18next";
import { Navbar, Header, Footer } from "../components";

import { styles } from "../styles";
const TentangPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      <Header title={t("about.header")} />
      <main
        className={`${styles.paddingX} flex flex-col gap-6 py-10 text-neutral-100`}
      >
        <h2 className={`${styles.headingM} text-center`}>
          <Trans i18nKey="about.vision.title">Visi</Trans>
        </h2>
        <p className="text-center">
          <Trans i18nKey="about.vision.subtitle">
            Mewujudkan Universitas Bereputasi Global Yang Menghasilkan Insan,
            Ilmu Pengetahuan, Teknologi, Seni Dan Budaya Yang Unggul.
          </Trans>
        </p>
        <h2 className={`${styles.headingM} text-center`}>
          <Trans i18nKey="about.mission.title">Misi</Trans>
        </h2>
        <ol className="ml-5 text-justify list-decimal">
          <li>
            <Trans i18nKey="about.mission.content_one">
              Menyelenggarakan Pendidikan Tinggi, Proses Pembelajaran Dan
              Keterampilan Yang Efektif, Berkualitas global dan Profesional
              Dengan
            </Trans>
            Pemanfaatan Teknologi Mukhtahir.
          </li>
          <li>
            <Trans i18nKey="about.mission.content_two">
              Menyelengarakan Penelitian Yang Berkualitas Dan Berkesinambungan
              Berstandarisasi Internasional Agar Bermanfaat Bagi Pengembangan
              Ilmu Serta Kesejahteraan Masyarakat
            </Trans>
          </li>
          <li>
            <Trans i18nKey="about.mission.content_three">
              Menyelenggarakan Kegiatan Pengabdian Kepada Masyarakat Berbasis
              Pada Penerapan Hasil Penelitian, Ilmu Pengetahuan, Teknologi, Seni
              Dan Budaya Sebagai Upaya Dalam Mendorong Peningkatan Kesejahteraan
              Masyarakat Secara Global
            </Trans>
          </li>
          <li>
            <Trans i18nKey="about.mission.content_four">
              Menerapkan Sistem Tata Kelola Yang Transparan, Akuntabel Dan
              Berstandarisasi Internasional Untuk Mencapai Efisiensi Dan
              Efektivitas Dalam Pengelolaan Sumber Daya
            </Trans>
          </li>
          <li>
            <Trans i18nKey="about.mission.content_five">
              Menyelenggarakan Kerjasama Dengan Berbagai Pihak Baik Dalam Maupun
              Luar Negeri Untuk Mengembangkan Institusi Guna Mewujudkan
              Pendidikan Tinggi Yang Mandiri Dan Meningkatkan Reputasi Institusi
              Pendidikan.
            </Trans>
          </li>
        </ol>
        <h2 className={`${styles.headingM} text-center`}>
          <Trans i18nKey="about.goals.title">Tujuan</Trans>
        </h2>
        <p className={styles.textLMedium}>
          <Trans i18nKey="about.goals.subtitle">
            Universitas Almarisah Madani memiliki beberapa tujuan sebagai
            berikut:
          </Trans>
        </p>
        <ol className="ml-5 list-decimal">
          <li>
            <p className="text-justify">{t("about.goals.content_one")}</p>
          </li>
          <li>
            <p className="text-justify">{t("about.goals.content_two")}</p>
          </li>
          <li>
            <p className="text-justify">{t("about.goals.content_three")}</p>
          </li>
          <li>
            <p className="text-justify">{t("about.goals.content_four")}</p>
          </li>
          <li>
            <p className="text-justify">{t("about.goals.content_five")}</p>
          </li>
        </ol>
      </main>
      <Footer />
    </>
  );
};

export default TentangPage;
