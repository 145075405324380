import { Trans, useTranslation } from "react-i18next";
import { FaChevronRight } from "react-icons/fa";
import { Navbar, Header, Footer, Button } from "../components";
import { styles } from "../styles";
const KontakPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      <Header title={t("contact.header")} />
      <section className={`${styles.paddingX} py-10`}>
        <div className="grid w-full place-items-center">
          <form action="" className="max-w-[1152px] w-full flex flex-col">
            <label
              htmlFor="nama"
              className={`mb-2 text-primary-40 ${styles.headingS}`}
            >
              <Trans i18nKey="contact.name.label">Nama Lengkap</Trans>
            </label>
            <input
              type="text"
              className="px-4 py-3 mb-6 border focus:outline-0 border-neutral-70 placeholder:text-neutral-80 placeholder:"
              placeholder={t("contact.name.placeholder")}
              id="nama"
            />
            <label
              htmlFor="email"
              className={`mb-2 text-primary-40 ${styles.headingS}`}
            >
              <Trans i18nKey="contact.email.label">Email</Trans>
            </label>
            <input
              type="email"
              className="px-4 py-3 mb-6 border focus:outline-0 border-neutral-70 placeholder:text-neutral-80 placeholder:"
              placeholder={t("contact.email.placeholder")}
              id="email"
            />
            <label
              htmlFor="tel"
              className={`mb-2 text-primary-40 ${styles.headingS}`}
            >
              <Trans i18nKey="contact.tel.label">Nomor Telepon</Trans>
            </label>
            <input
              type="tel"
              className="px-4 py-3 mb-6 border focus:outline-0 border-neutral-70 placeholder:text-neutral-80 placeholder:"
              placeholder={t("contact.tel.placeholder")}
              id="tel"
            />
            <label
              htmlFor="pertanyaan"
              className={`mb-2 text-primary-40 ${styles.headingS}`}
            >
              <Trans i18nKey="contact.content.label">
                Tuliskan Pertanyaan Anda
              </Trans>
            </label>
            <textarea
              type="text"
              className="px-4 py-3 mb-6 h-[240px] border focus:outline-0 border-neutral-70 placeholder:text-neutral-80 resize-none"
              placeholder={t("contact.content.placeholder")}
              id="Pertanyaan"
            />
            <div className="flex items-center justify-center w-full">
              <Button
                title={t("contact.submit")}
                icon={<FaChevronRight />}
                customization="text-neutral-10 bg-primary-40"
                type="submit"
                onClick={(evt) => evt.preventDefault()}
              />
            </div>
          </form>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default KontakPage;
