export const MARKDOWN_PARSER_COMPONENTS_CUSTOMIZATION = {
  table: ({ node, ...props }) => (
    <div className="w-full overflow-x-scroll scrollbar-hide">
      <table className="w-full"
        {...props}
      />
    </div>
  ),
  thead: ({ node, ...props }) => (
    <thead className="text-neutral-white"
      {...props}
    />
  ),
  tr: ({ node, ...props }) => (
    <tr className="odd:bg-neutral-40"
      {...props}
    />
  ),
  td: ({ node, ...props }) => (
    <td className="px-8 py-2 text-nowrap"
      {...props}
    />
  ),
  th: ({ node, ...props }) => (
    <th className="p-2 font-bold bg-primary-50 text-neutral-white"
      {...props}
    />
  ),
  p: ({ node, ...props }) => (
    <p style={{ wordBreak: "break-word", textAlign: "justify" }}
      {...props}
    />
  ),
  a: ({ node, ...props }) => (
    <a style={{ wordBreak: "break-word" }}
      {...props}
    />
  ),
  h1: ({ node, ...props }) => (
    <h1 className="text-primary-50"
      {...props}
    />
  ),
  h2: ({ node, ...props }) => (
    <h2 className="text-primary-50"
      {...props}
    />
  ),
  h3: ({ node, ...props }) => (
    <h3 className="text-primary-50"
      {...props}
    />
  ),
  h4: ({ node, ...props }) => (
    <h4 className="text-primary-50"
      {...props}
    />
  ),
}
