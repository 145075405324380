import { FaChevronUp } from "react-icons/fa";
import { styles } from "../../styles";

const Accordion = ({ title, isOpen, data, toggleAccordion, list }) => {
  return (
    <div className="mb-1 overflow-hidden border rounded-md">
      <button
        className="flex items-center justify-between w-full p-4 text-left transition duration-300 bg-neutral-20 hover:bg-neutral-40"
        onClick={toggleAccordion}
      >
        <span className={`${styles.textLMedium}`}>{title}</span>
        <FaChevronUp
          className={`transform ${isOpen ? "rotate-0" : "rotate-180"
            } transition-transform duration-300`}
        />
      </button>
      {isOpen && <div className="p-4 bg-white">{data}{
        list !== undefined &&
        list.map((list, index) => (
          <p className="" key={index}>
            {list}
          </p>
        ))
      }</div>}

    </div>
  );
};

export default Accordion;
