import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import {
  FaChevronCircleLeft,
  FaChevronCircleRight,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import { Navigate } from "react-router-dom";

import { ArticleCard, Footer, Header, Navbar } from "../components";
// import { articles } from "../constants";
import { styles } from "../styles";
import customAxios from "../utils/customAxios";
import Loader from "../components/Loader";

const ArtikelPage = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [pagination, setPagination] = useState(1);
  const [meta, setMeta] = useState(null);
  const [data, setData] = useState(null);
  // const articlesReversed = [...articles].reverse()

  const getArticles = async () => {
    const lang = /^en(-\w+)?$/.test(currentLanguage)
      ? "en"
      : /^id(-\w+)?$/.test(currentLanguage)
      ? "id"
      : "en";
    return await customAxios.get(`articles?lang=${lang}&page=${pagination}`);
  };

  const {
    data: articlesData,
    isFetching,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["data", pagination, currentLanguage],
    queryFn: () => getArticles(),
    placeholderData: keepPreviousData, // don't have 0 rows flash while changing pages/loading next page
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (articlesData) {
      setMeta(articlesData.data.meta);
      setData(articlesData.data.data);
    }
  }, [articlesData]);

  if (isError) return <Navigate to="/error"></Navigate>;

  return (
    <>
      <Navbar />
      <Header title={t("article.header")} />
      <div
        className={`${styles.paddingX} flex flex-wrap items-center justify-center gap-10 py-10`}
      >
        {isLoading || isFetching ? (
          <div className="grid place-items-center h-[30dvh]">
            <Loader />
          </div>
        ) : (
          <>
            {/* Empty state */}
            {!data?.length && (
              <div className="h-[30dvh] grid place-items-center">
                {t("article.no_article")}
              </div>
            )}

            {/* Cards */}
            {data?.map((article, index) => {
              return <ArticleCard {...article} key={index} />;
            })}

            {/* Pagination Control */}
            {!!data?.length && (
              <div
                className={`flex items-center gap-6 justify-center w-full sticky left-0 sm:flex-col`}
              >
                <div className="flex gap-2">
                  <button
                    className="p-2 border rounded text-primary-60 hover:bg-primary-60 hover:text-neutral-white disabled:hidden"
                    onClick={() => setPagination(1)}
                    disabled={pagination === 1}
                  >
                    <FaChevronCircleLeft />
                  </button>
                  <button
                    className="p-2 border rounded text-primary-60 hover:bg-primary-60 hover:text-neutral-white disabled:hidden"
                    onClick={() =>
                      setPagination((previousState) => previousState - 1)
                    }
                    disabled={pagination === 1}
                  >
                    <FaChevronLeft />
                  </button>
                  <button
                    className="p-2 border rounded text-primary-60 hover:bg-primary-60 hover:text-neutral-white disabled:hidden"
                    onClick={() =>
                      setPagination((previousState) => previousState + 1)
                    }
                    disabled={pagination === meta?.last_page}
                  >
                    <FaChevronRight />
                  </button>
                  <button
                    className="p-2 border rounded text-primary-60 hover:bg-primary-60 hover:text-neutral-white disabled:hidden"
                    onClick={() => setPagination(meta?.last_page)}
                    disabled={pagination === meta?.last_page}
                  >
                    <FaChevronCircleRight />
                  </button>
                </div>
                <span className="flex items-center gap-1">
                  <div>Page</div>
                  <strong>
                    {pagination} of {meta?.last_page}
                  </strong>
                </span>
                <span className="flex items-center gap-1">
                  Go to page:
                  <input
                    type="number"
                    defaultValue={pagination}
                    onBlur={(e) => {
                      if (Number(e.target.value) > meta?.last_page)
                        setPagination(meta?.last_page);
                      else if (Number(e.target.value) <= 0) setPagination(1);
                      else {
                        const page = e.target.value
                          ? Number(e.target.value) - 1
                          : 0;
                        setPagination(page);
                      }
                    }}
                    className="w-16 p-1 border rounded"
                  />
                </span>
              </div>
            )}
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default ArtikelPage;
