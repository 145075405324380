import { Link } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { FaRegCalendarAlt } from "react-icons/fa";
import { styles } from "../../styles";
import Button from "../Button";
import { formatDate } from "../../utils/helper";

const EventCard = ({ thumbnail, slug, title, created_at }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center bg-neutral-10 border-neutral-40 w-[400px] h-[456px] border-[1px]">
      <img
        src={thumbnail}
        alt={title}
        className="w-[400px] h-[300px] object-cover"
      />
      <div className="flex flex-col items-start justify-start w-full gap-6 p-6">
        <div className="flex flex-col items-start self-stretch gap-2">
          <h1 className={`${styles.headingM} text-neutral-90`}>{title}</h1>
          <div className="flex gap-6 divide-x-2">
            <div className="flex gap-1">
              <FaRegCalendarAlt className="w-[16px] h-[16px] fill-info-90" />
              <p className={`${styles.textSRegular} text-info-90`}>
                {formatDate(created_at)}
              </p>
            </div>
          </div>
        </div>
        <Link to={slug}>
          <Button
            title={t("events.view")}
            customization="hover:underline text-primary-40 ml-[-24px]"
            icon={<FaChevronRight />}
          />
        </Link>
      </div>
    </div>
  );
};

export default EventCard;
