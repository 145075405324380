import { languages, navLinks } from "../../constants";
import { logo } from "../../assets";
import { FaChevronDown, FaExternalLinkAlt } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { styles } from "../../styles";
import { useTranslation, Trans } from "react-i18next";

const Navbar = ({ mref }) => {
  const navigate = useNavigate();
  const [links, setLinks] = useState(navLinks);
  const [currPage, setCurrPage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownToggled, setDropdownToggled] = useState(false);
  const [langIsOpen, setLangIsOpen] = useState(false);
  const { i18n } = useTranslation();

  useEffect(() => {
    setCurrPage(window.location.pathname);
  }, []);

  const handleOnClick = (path) => {
    setDropdownToggled(false);
    navigate(path);
  };

  const handleToggleDropdown = ({ id }) => {
    const updatedLinks = links.map((link) => {
      if (link.id === id) return { ...link, isActive: !link.isActive };
      else return { ...link, isActive: false };
    });
    setLinks(updatedLinks);
    setLangIsOpen(false);
  };

  const closeAllDropdown = () => {
    const updatedLinks = links.map((link) => {
      return { ...link, isActive: false };
    });
    setLinks(updatedLinks);
  };

  return (
    <nav
      className={`${styles.paddingX} flex w-full h-[64px] items-center justify-between z-20 px-[50px] text-primary-90 bg-neutral-10 sticky top-0`}
      ref={mref}
    >
      <Link to="/" className="flex gap-[16px] items-center justify-center">
        <img src={logo} alt="logo" className="h-[40px]" />
        <p className="font-medium text-primary-40 sm:hidden">
          <Trans i18nKey="navbar.title">Universitas Almarisah Madani</Trans>
        </p>
      </Link>

      <div className="flex items-center h-[100%]">
        <ul className="flex items-center justify-center list-none smd:hidden">
          {links.map((link) => {
            return (
              <li
                className={`px-[24px] font-medium text-primary-40 flex items-center justify-center gap-1 hover:bg-neutral-40 cursor-pointer h-16 transition ease-in-out duration-150 relative ${
                  link.isActive ? "bg-neutral-40" : ""
                } ${
                  ["/", "/kontak"].includes(currPage) &&
                  link.path === currPage &&
                  "border-b-[3px] border-primary-40"
                }`}
                key={link.id}
                onClick={
                  ["beranda", "kontak"].includes(link.id)
                    ? () => {
                        handleOnClick(link.path);
                      }
                    : () => {
                        handleToggleDropdown(link);
                      }
                }
              >
                <p>
                  <Trans i18nKey={link.i18nKey}>{link.title}</Trans>
                </p>
                {!["beranda", "kontak"].includes(link.id) && (
                  <FaChevronDown
                    className={`${
                      link.isActive && "rotate-180"
                    } transition ease-in-out duration-150`}
                    key={link.id}
                  />
                )}

                {/* Dropdown */}
                {link.child !== undefined && (
                  <ul
                    className={`absolute right-0 flex flex-col divide-y w-max top-0 mt-16 ${
                      !link.isActive && "hidden"
                    }`}
                  >
                    {link.child.map((option) => {
                      const { id, title, path, i18nKey } = option;
                      return ["penerimaan-mahasiswa"].includes(id) ? (
                        <Link to="/redirect" state={{ url: path }} key={id}>
                          <li
                            className="px-4 py-3 bg-neutral-10 h-[48px] flex gap-4 items-center hover:bg-neutral-40 cursor-pointer transition ease-in-out duration-150"
                          >
                            <p>
                              <Trans i18nKey={i18nKey}>{title}</Trans>
                            </p>
                            <FaExternalLinkAlt />
                          </li>
                        </Link>
                      ) : (
                        <Link to={path} key={id}>
                          <li
                            className="px-4 py-3 bg-neutral-10 h-[48px] flex gap-4 items-center hover:bg-neutral-40 cursor-pointer transition ease-in-out duration-150"
                          >
                            <p>
                              <Trans i18nKey={i18nKey}>{title}</Trans>
                            </p>
                          </li>
                        </Link>
                      );
                    })}
                  </ul>
                )}
              </li>
            );
          })}
        </ul>

        <div
          className="relative flex items-center h-full gap-2 px-3 cursor-pointer text-primary-40"
          onClick={() => {setLangIsOpen((prevState) => !prevState); closeAllDropdown(); setIsOpen(false)}}
        >
          {i18n.resolvedLanguage === "id" ? (
            <img src={languages.id.icon} alt="locale icon" />
          ) : (
            <img src={languages.en.icon} alt="locale icon" />
          )}
          <FaChevronDown
            className={`${
              langIsOpen && "rotate-180"
            } transition ease-in-out duration-150`}
          />
          <div
            className={`top-[64px] right-0 absolute bg-white w-[120px] z-20 ${
              langIsOpen ? "" : "hidden"
            }`}
          >
            {Object.keys(languages).map((lang) => (
              <div
                key={lang}
                onClick={() => {
                  i18n.changeLanguage(lang);
                }}
                className="flex items-center gap-2 px-3 py-2"
              >
                <img src={languages[lang].icon} alt="locale icon" />
                <p>{languages[lang].nativeName}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Tablet Navbar */}
        {/* Hamburger */}
        <div className="hidden ml-4 smd:block">
          <div
            className="relative w-[32px] h-[32px] flex flex-col items-center justify-around"
            onClick={() => {
              setIsOpen(!isOpen);
              setLangIsOpen(false);
            }}
          >
            <span
              className={`h-1 w-full rounded-[10px] bg-primary-40 ${
                isOpen ? "rotate-[-45deg]" : "rotate-0"
              } transtition-transform duration-300 origin-[top_right]`}
            ></span>
            <span
              className={`h-1 w-full rounded-[10px] bg-primary-40 ${
                isOpen ? "opacity-0" : "opacity-100"
              } transtition-transform duration-200`}
            ></span>
            <span
              className={`h-1 w-full rounded-[10px] bg-primary-40 ${
                isOpen ? "rotate-45" : "rotate-0"
              } transtition-transform duration-300 origin-[bottom_right]`}
            ></span>
          </div>
          <ul
            className={`${
              styles.textLMedium
            } absolute top-0 left-0 w-full mt-[64px] h-[100vh] divide-y text-primary-40 ${
              isOpen ? "translate-x-0" : "translate-x-[-150%]"
            } transtition-transform duration-200`}
          >
            {links.map((link) => {
              return (
                <React.Fragment key={link.title}>
                  <li
                    className={`${styles.paddingX} flex items-center justify-between w-full h-12 bg-neutral-10`}
                    onClick={() => {
                      handleOnClick(link.path);
                      handleToggleDropdown(link);
                    }}
                  >
                    <p>
                      <Trans i18nKey={link.i18nKey}>{link.title}</Trans>
                    </p>
                    {link.child !== undefined && (
                      <FaChevronDown
                        className={`${
                          link.isActive ? "rotate-180" : "rotate-0"
                        } transition-transform duration-300`}
                      />
                    )}
                  </li>

                  {/* Dropdown */}
                  {link.child !== undefined && (
                    <ul
                      className={`flex flex-col divide-y w-full ${
                        !link.isActive ? "hidden" : ""
                      } transtition-transform duration-200`}
                    >
                      {link.child.map((option) => {
                        const { id, title, path, i18nKey } = option;
                        return ["penerimaan-mahasiswa"].includes(id) ? (
                          <Link to="/redirect" state={{ url: path }} key={id}>
                            <li
                              className="px-20 sm:px-10 py-3 bg-neutral-30 h-[48px] flex justify-between items-center hover:bg-neutral-40 cursor-pointer transition ease-in-out duration-150"
                            >
                              <p>
                                <Trans i18nKey={i18nKey}>{title}</Trans>
                              </p>
                              <FaExternalLinkAlt />
                            </li>
                          </Link>
                        ) : (
                          <Link to={path} key={id}>
                            <li
                              className="px-20 sm:px-10 py-3 bg-neutral-30 h-[48px] flex justify-between items-center hover:bg-neutral-40 cursor-pointer transition ease-in-out duration-150"
                            >
                              <p>
                                <Trans i18nKey={i18nKey}>{title}</Trans>
                              </p>
                            </li>
                          </Link>
                        );
                      })}
                    </ul>
                  )}
                </React.Fragment>
              );
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
