import { Trans, useTranslation } from "react-i18next";
import { FaFolder } from "react-icons/fa";
import { styles } from "../styles";
import { Navbar } from "../components";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  const { t } = useTranslation();
  return (
    <section>
      <Navbar />
      <div className="flex flex-col items-center justify-center max-w-full h-full pt-[160px] lg:px-36 md:px-16 sm:px-6">
        <div className="relative flex items-center justify-center">
          <h1 className={`${styles.headingL} absolute text-white mt-4`}>404</h1>
          <FaFolder className="fill-neutral-90 h-[180px] w-[180px]" />
        </div>
        <div className="flex flex-col items-center justify-center">
          <h1 className={`${styles.headingL} text-center text-danger-40`}>
            <Trans i18nKey="error.title">Halaman Tidak ditemukan</Trans>
          </h1>
          <p
            className={`lg:${styles.textLRegular} sm:text-[12px] sm-leading-4 text-neutral-90 md:text-center`}
          >
            <Trans i18nKey="error.subtitle">
              Kami tidak dapat menemukan halaman yang anda cari. Anda dapat
              kembali ke
            </Trans>{" "}
            <Link to="/" className="text-danger-40 hover:underline">
              <Trans i18nKey="error.link">halaman utama.</Trans>
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default ErrorPage;
