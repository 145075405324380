import { useTranslation } from "react-i18next";
import { Navbar, Header, Footer, ImageAccordion } from "../components";
import { useState } from "react";
import { tuitonAccordion } from "../constants";
import { styles } from "../styles";

const BiayaPendidikanPage = () => {
  const [accordions, setAccordion] = useState(tuitonAccordion);
  const { t } = useTranslation();

  const toggleAccordion = (accordionkey) => {
    const updatedAccordions = accordions.map((accord) => {
      if (accord.key === accordionkey) {
        return { ...accord, isOpen: !accord.isOpen };
      } else {
        return { ...accord, isOpen: false };
      }
    });

    setAccordion(updatedAccordions);
  };

  return (
    <>
      <Navbar />
      <Header title={t("tuition.header")} />
      <main className={`${styles.paddingX} flex flex-col gap-6 py-10`}>
        <div>
          {accordions.map((accordion) => (
            <ImageAccordion
              {...accordion}
              title={t(`tuition.question_${accordion.key}`)}
              toggleAccordion={() => toggleAccordion(accordion.key)}
            />
          ))}
        </div>
      </main>
      <Footer />
    </>
  );
};

export default BiayaPendidikanPage;
