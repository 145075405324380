import { styles } from "../../styles";
import { logo } from "../../assets";
import { useTranslation, Trans } from "react-i18next";

const LectureProfileCard = (props) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center h-fit md:scale-[0.8] sm:scale-[0.6]">
      <div className="mt-10 relative rounded-tl-[125px] rounded-br-[125px] bg-primary-40 w-[250px] h-[250px] sm:mt-[-40px]">
        <div className="absolute bottom-0">
          <img
            src={props.photo}
            alt={props.photo}
            className="rounded-br-[125px]"
          />
        </div>
        {/* <img
          src={logo}
          alt=""
          className="absolute w-[40px] h-[40px] bottom-[-20px] m-auto left-0 right-0 "
        /> */}
      </div>
      <p
        className={`${styles.headingS} mt-4 text-primary-40 w-[400px] text-center`}
      >
        {props.name}
      </p>
      <p className={`${styles.textMMedium} w-[300px] mt-2 text-center`}>
        <Trans i18nKey={`lectures.${props.id}.position.${props.list}`}>{props.position}</Trans>
      </p>
    </div>
  );
};

export default LectureProfileCard;
