import { useEffect } from "react";
import { logo } from "../assets";
import { styles } from "../styles";
import { useLocation } from "react-router-dom";

const RedirectingPage = () => {
  const location = useLocation();
  const { url } = location.state;
  useEffect(() => {
    window.location.replace(url);
  }, [url]);
  return (
    <>
      <main className="relative grid h-screen place-items-center">
        <h1 className={`${styles.headingL} text-primary-40`}>Redirecting...</h1>
        <img
          src={`${logo}`}
          alt="logo"
          className="absolute z-[-1] opacity-[15%]"
        />
      </main>
    </>
  );
};

export default RedirectingPage;
