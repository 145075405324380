import { useTranslation } from "react-i18next";
import { styles } from "../../styles";
import { Link } from "react-router-dom";
import { formatDate } from "../../utils/helper";

const ArticleCard = ({ thumbnail, slug, title, description, created_at }) => {
  return (
    <div className="flex flex-col justify-start items-center w-[350px] h-[394px] sm:w-[320px]">
      <img src={thumbnail} alt={title} className="h-[230px] object-cover w-full" />
      <div className="flex flex-col items-start self-stretch justify-start gap-2 py-4">
        <div className="flex flex-col items-start self-stretch gap-2">
            <Link
              to={slug}
              className={`${styles.headingS} text-start text-neutral-90 line-clamp-3 hover:underline`}
            >
              {title}
            </Link>
          <p
            className={`${styles.textSRegular} text-start text-neutral-80 w-full self-stretch whitespace-nowrap truncate`}
          >
            {description}
          </p>
        </div>
        <p className={`${styles.textSMedium} text-start text-primary-40`}>
          {formatDate(created_at)}
        </p>
      </div>
    </div>
  );
};

export default ArticleCard;
