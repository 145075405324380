import React from "react";
import { rektor } from "../assets";
import { Header, Navbar, Footer } from "../components";
import { styles } from "../styles";
import { useTranslation, Trans } from "react-i18next";

const SambutanPage = () => {
  const { t } = useTranslation();
  const textWithNewLine = t("sambutan.greetings")
    .split("\n")
    .map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  return (
    <>
      <Navbar />
      <Header title={t("sambutan.header")} />

      <section
        className={`${styles.paddingX} flex sm:flex-col w-full gap-8 py-10 text-neutral-100`}
      >
        <div className="flex flex-col items-center w-full gap-4">
          <img src={rektor} alt="rektor univeral" className="max-w-[280px]" />
          <div className="flex flex-col items-center gap-2">
            <h3 className={`${styles.headingS} text-primary-40`}>
              DR. Nursamsiar. M.Si.
            </h3>
            <p className={`${styles.textLRegular}`}>
              <Trans i18nKey="sambutan.title">
                Rektor Universitas Almarisah Madani
              </Trans>
            </p>
          </div>
        </div>
        <div>
          <p>
            <Trans i18nKey="sambutan.greetings">{textWithNewLine}</Trans>
          </p>
          <br />
          <p>
            <Trans i18nKey="sambutan.content">Hormat saya,</Trans>
          </p>
          <p>DR. Nursamsiar M.Si.,</p>
          <p>
            <Trans i18nKey="sambutan.title">
              Rektor, Universitas Almarisah Madani
            </Trans>
          </p>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default SambutanPage;
