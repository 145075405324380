import { styles } from "../../styles";

const Button = ({ title, icon, customization, type, onClick }) => {
  return (
    <button
      className={`flex gap-1 px-6 py-3 rounded items-center cursor-pointer ${customization}`}
      type={type}
      onClick={onClick}
    >
      <p className={`${styles.textLMedium}`}>{title}</p>
      {icon}
    </button>
  );
};

export default Button;
