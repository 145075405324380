import React, { useState } from "react";
import { styles } from "../../styles";

const NavTabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="w-screen">
      <div className="flex justify-center w-full gap-4 mt-10 md:px-16 md-justify-between sm:px-6">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`px-4 py-2 cursor-pointer grid place-items-center text-center ${
              activeTab === index ? "bg-primary-40 text-white" : "bg-neutral-30"
            } sm:text-[12px]`}
            onClick={() => handleTabClick(index)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className="flex flex-wrap justify-center w-full my-20 px-36 md:px-16 sm:px-4">{tabs[activeTab].content}</div>
    </div>
  );
};

export default NavTabs;
