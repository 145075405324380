import { Link } from "react-router-dom";
import { styles } from "../../styles";

const StudyCard = ({ img, subject, number, link }) => {
  return (
    <Link to={link.toLowerCase()}>
      <div className="group">
        <div className="overflow-hidden group-hover:scale-105 ease-in-out duration-300 relative flex flex-col w-[230px] h[230px] px-2 pt-2 pb-4 justify-flex-end items-center gap-4 bg-white border-1 cursor-pointer rounded">
          {/* <div
        style={{ background: `url(${img}) center/cover` }}
        className="w-[214px] h-[162px] rounded-tr-[48px] rounded-bl-[48px]"
      ></div> */}

        <div
          // src={img}
          alt={subject}
          style={{ background: `url(${img}) center/cover` }}
          className="w-[214px] h-[162px] rounded-tr-[48px] rounded-bl-[48px] rounded-tl rounded-br object-cover"
        />

          <h1
            className={`${styles.headingS} text-center text-primary-90 group-hover:text-primary-40 ease-in-out duration-300`}
          >
            {subject}
          </h1>
          <p className="absolute text-neutral-90/20 text-[100px] bottom-[-59px] left-0">
            {number}
          </p>
        </div>
      </div>
    </Link>
  );
};
export default StudyCard;
