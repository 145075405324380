import { useParams, Navigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { FaFileDownload } from "react-icons/fa";
import { Footer, Header, Navbar, SiluetCard, Button } from "../components";
import { programStudi } from "../constants";
import { styles } from "../styles";
import { useTranslation, Trans } from "react-i18next";

const ProgramPage = () => {
  const { depart } = useParams();
  const chosenProgram = useRef({});
  const isFound = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const [translationData, setTranslationData] = useState(null);
  const handleDownload = () => {
    const link = document.createElement("a");
    link.download = `${kurikulum}`;

    link.href = chosenProgram.current.details.kurikulum;
    link.click();
  };

  useEffect(() => {
    chosenProgram.current = programStudi.filter((program) => {
      const page = program.link.split("/").at(-1);
      return depart === page;
    })[0];

    isFound.current = Object.keys(chosenProgram.current).length > 0;
    setIsLoading(false);
  }, [depart]);

  if (isLoading)
    return (
      <div
        className={`${styles.headingL} grid w-screen h-screen place-items-center text-primary-40`}
      >
        Loading...
      </div>
    );

  if (!isLoading && !isFound) return <Navigate to="/error"></Navigate>;

  const { subject, details, career } = chosenProgram.current;
  const prodiIndex = programStudi.findIndex(
    (program) => program.subject === chosenProgram.current.subject
  );
  const {
    desc,
    listDeskripsi,
    karir,
    listKarir,
    keunggulan,
    image,
    kurikulum,
  } = details;
  return (
    <>
      <Navbar />
      <Header title={t(`prodi.${prodiIndex}.subject`)} />
      <main className={`${styles.paddingX} py-10`}>
        <div>
          <p
            className={`${styles.headingL} text-center text-primary-40 font-bold`}
          >
            <Trans i18nKey={`prodi.${prodiIndex}.subject`}>
              {t(subject)}
            </Trans>
          </p>
          <br />
          <div className="flex flex-col gap-4">
            <div>
              <p className={`${styles.textLMedium} text-primary-40 font-bold`}>
                <Trans i18nKey={`prodi.${prodiIndex}.details.desc`}>
                  {t(desc)}
                </Trans>
              </p>
            </div>

            <div className="flex flex-col gap-4">
              <p>
                <Trans i18nKey={`prodi.${prodiIndex}.details.excellence`}>
                  {t(keunggulan)}
                </Trans>
              </p>
              <ol
                className="flex flex-col"
                style={{ listStyleType: "decimal" }}
              >
                {listDeskripsi.map((list, index) => (
                  <li className="ml-4" key={index}>
                    <p>
                      <Trans
                        i18nKey={`prodi.${prodiIndex}.details.listDesc.${index}.subTitle`}
                      >
                        {t(list.subTitle)}
                      </Trans>
                    </p>
                    <ul className="mb-2">
                      <ol
                        className="flex flex-col ml-4"
                        style={{ listStyleType: "disc" }}
                      >
                        {list.descTitle.map((subItem, subIndex) => (
                          <li key={subIndex}>
                            <Trans
                              i18nKey={`prodi.${prodiIndex}.details.listDesc.${index}.descTitle.${subIndex}`}
                            >
                              {t(subItem)}
                            </Trans>
                          </li>
                        ))}
                      </ol>
                    </ul>
                  </li>
                ))}
              </ol>
            </div>
          </div>
          <br />

          <div className="flex flex-col gap-4">
            <div>
              <p className={`${styles.textLMedium} text-primary-40 font-bold`}>
                <Trans i18nKey={`prodi.${prodiIndex}.details.career`}>
                  {t(career)}
                </Trans>
              </p>
            </div>

            <div>
              <ol
                className="flex flex-col"
                style={{ listStyleType: "decimal" }}
              >
                {listKarir.map((list, index) => (
                  <li className="ml-4" key={index}>
                  <Trans
                    i18nKey={`prodi.${prodiIndex}.details.listCareer.${index}`}
                  >
                    {t(list)}
                  </Trans>
                </li>
                ))}
              </ol>
            </div>
            <br></br>
            <Button
              onClick={handleDownload}
              title={t(`prodi.${prodiIndex}.details.kurikulum`)}
              icon={<FaFileDownload />}
              customization={`${styles.textLRegular} bg-primary-40 hover:bg-primary-50 text-white w-fit self-center`}
            />
          </div>
        </div>
      </main>
      <SiluetCard />
      <Footer />
    </>
  );
};

export default ProgramPage;
