import { styles } from "../../styles";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

const BeasiswaCard = (props) => {
  const { t } = useTranslation();
  return (
    <div className="h-[230px] w-fit flex">
      <div
        className="h-[200px] w-[334px] self-end flex flex-col 
                          justify-center content-center items-center 
                          rounded-xl shadow-[6px_8px_19px_-1px_rgba(0,0,0,0.3)]"
      >
        <div className="rounded-xl h-[140px] w-[140px] relative bottom-[35px]">
          <img src={props.img} />
        </div>

        <div className="relative bottom-[20px] text-center">
          <p
            className={`${styles.textM} text-center text-neutral-90 font-bold`}
          >
            <Trans i18nKey={`scholarship.content.${props.index}`}>
              {props.title}
            </Trans>
          </p>
          <Link
            to={props.path}
            className={`${styles.textSMedium} text-center text-warning-40 hover:underline italic pb-1 text-center`}
          >
            <Trans i18nKey="scholarship.details">Selengkapnya &#62;</Trans>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BeasiswaCard;
