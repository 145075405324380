import { Link } from "react-router-dom";
import { Navbar, Footer, StudyCard, Button } from "../components";
import { landingheader4, bgcta, reachusillustration, logo } from "../assets";
import {
  partners,
  universityStats,
  programStudi,
  landingHeaderImgs,
} from "../constants";
import { styles } from "../styles";
import { useEffect, useRef, useState } from "react";
import { useTranslation, Trans } from "react-i18next";

const LandingPage = () => {
  const TO_SCROLL = useRef(null);
  const [scrolled, setScrolled] = useState(0);
  const [timer, setTimer] = useState(0);
  const [screenWidth, setScreenWidth] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    setScreenWidth(window.innerWidth);
    const container = document.querySelector("#slideshowWrapper");
    const width = container.scrollWidth;
    TO_SCROLL.current =
      document.querySelector("#slideshowWrapper").scrollWidth / 4;
    setTimeout(() => {
      if (scrolled >= width) {
        container.scrollLeft = 0;
        setScrolled(0);
      } else {
        container.scrollLeft = scrolled + TO_SCROLL.current;
        setScrolled((prev) => prev + TO_SCROLL.current);
      }
      setTimer((prev) => prev + 1);
    }, 4000);
  }, [timer]);

  return (
    <>
      <Navbar />
      <section className="w-full relative h-[600px] smd:h-full grid place-items-center">
        <div
          id="slideshowWrapper"
          className="h-[600px] w-[100%] grid place-items-center overflow-x-hidden snap-mandatory snap-x overflow-y-hidden"
        >
          <img
            src={logo}
            alt="logo"
            className="absolute left-0 right-0 mx-auto w-[400px] mb-[100px] md:w-[300px] sm:w-[200px] smd:top-0 smd:bottom-0 md:mt-[150px] sm:mt-[200px] smd:mb-0 z-10"
          />
          <div className={`flex`}>
            {landingHeaderImgs.map(({ img, position }, index) => (
              <div
                key={index}
                style={{
                  background: `linear-gradient(#00000050, #00000050), url(${img}) ${position}/cover no-repeat`,
                }}
                className={`h-[600px] w-screen snap-center`}
              ></div>
            ))}
          </div>
        </div>
        <div className="w-full px-30 grid place-items-center absolute smd:static bottom-[-108px] mx-30">
          <div className="w-full max-w-[1200px] h-full py-8 smd:rounded-none rounded-[16px] bg-white flex flex-col items-center justify-center gap-6">
            <h2 className={styles.headingM}>
              <Trans i18nKey="landing.statistics.title">
                Univeral dalam Angka
              </Trans>
            </h2>
            <div className="grid w-full grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-y-6">
              {universityStats.map((stat, index) => {
                return (
                  <div
                    className={`flex flex-col justify-center items-center gap-2 min-w-[250px] sm:py-1 ${
                      stat.textColor
                    } 
                    ${
                      index === 1
                        ? "border-x md:border-r-0 sm:border-x-0 border-black"
                        : ""
                    }
                    ${
                      index === 3 && "md:col-span-1 border-x md:border-r-0 sm:border-x-0 border-black"
                    }
                    `}
                    key={index}
                  >
                    <h1 className={styles.headingL}>{stat.total}</h1>
                    <p className={styles.textLMedium}>
                      <Trans i18nKey={stat.i18nKey}>{stat.title}</Trans>
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <section
        className={`${styles.paddingX} flex flex-col items-center justify-center w-full gap-20 pt-40 smd:pt-20 pb-20 relative overflow-hidden`}
      >
        <div className="absolute top-0 grid place-items-center h-[100%] w-inherit z-[-1] ">
          <div className="h-[100%] w-full bg-gradient-to-tr from-primary-40 to-primary-80">
            <img
              src={landingheader4}
              alt=""
              className="object-cover h-full opacity-40 grayscale smd:opacity-0"
            />
          </div>
        </div>
        <div className="flex flex-col gap-10 ">
          <div className="text-center text-white max-w-225">
            <h1 className={`${styles.headingM} mb-2`}>
              <Trans i18nKey="landing.studyProgram.title">Program Studi</Trans>
            </h1>
            <p>
              <Trans i18nKey="landing.studyProgram.subtitle">
                Univeral memiliki 10 program studi yang sesuai dengan kebutuhan
                industri saat ini,{" "}
                <i>Klik pada salah satu program studi untuk melihat detail.</i>
              </Trans>
            </p>
          </div>
          <div className="max-w-[1150px] flex flex-wrap items-center justify-center gap-20 ">
            {programStudi.map(({ img, subject, link, id }, index) => {
              return (
                <StudyCard
                  img={img}
                  subject={t(`landing.studyProgram.child.${id}`)}
                  link={link}
                  number={index > 8 ? `${index + 1}` : `0${index + 1}`}
                  key={index}
                />
              );
            })}
          </div>
        </div>
      </section>

      <section className={`${styles.paddingX} py-20`}>
        <div className="mb-8 text-center max-w-900">
          <h1 className={`${styles.headingM} mb-3 text-primary-40`}>
            <Trans i18nKey="landing.partners.title">Partner Univeral</Trans>
          </h1>
          <p>
            <Trans i18nKey="landing.partners.subtitle">
              Univeral berkomitmen untuk menyediakan edukasi yang terbaik untuk
              seluruh mahasiswanya. Dengan itu, Univeral bekerjasama dengan
              beberapa institusi ternama
            </Trans>
          </p>
        </div>
        <div className="flex flex-col items-center justify-center gap-5 smd:w-full">
          <div className="w-[100%] flex flex-row flex-1 overflow-x-hidden snap-mandatory snap-x overflow-y-hidden relative">
            <div className="flex gap-[10px] animate-marquee1">
              {partners.insitutions.map((partner, index) => {
                return (
                  <div className="border border-gray w-[174px] h-[104px] overflow-hidden flex items-center justify-center p-2 rounded-[8px]" key={index}>
                    <img
                      src={partner}
                      alt="univeral partner"
                      key={index}
                      className="h-[100%]"
                    />
                  </div>
                );
              })}
            </div>
            <div className="absolute flex gap-[10px] animate-marquee2">
              {partners.insitutions.map((partner, index) => {
                return (
                  <div className="border border-gray w-[174px] h-[104px] overflow-hidden flex items-center justify-center p-2 rounded-[8px]" key={index}>
                    <img
                      src={partner}
                      alt="univeral partner"
                      key={index}
                      className="h-[100%]"
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-[100%] flex flex-row flex-1 overflow-x-hidden snap-mandatory snap-x overflow-y-hidden relative">
            <div className="flex gap-[10px] animate-marquee1">
              {partners.universities.map((partner, index) => {
                return (
                  <div className="border border-gray w-[174px] h-[104px] overflow-hidden flex items-center justify-center p-2 rounded-[8px]">
                    <img
                      src={partner}
                      alt="univeral partner"
                      key={index}
                      className="h-[100%]"
                    />
                  </div>
                );
              })}
            </div>
            <div className="absolute flex gap-[10px] animate-marquee2">
              {partners.universities.map((partner, index) => {
                return (
                  <div className="border border-gray w-[174px] h-[104px] overflow-hidden flex items-center justify-center p-2 rounded-[8px]">
                    <img
                      src={partner}
                      alt="univeral partner"
                      key={index}
                      className="h-[100%]"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      <section className="my-20">
        <div
          className={`${styles.paddingX} items-center justify-center w-full py-10`}
        >
          <div className="relative flex items-center w-full gap-8 py-10 rounded-lg h-fit bg-primary-40">
            <div className="absolute flex items-center w-full h-full overflow-hidden">
              <div
                style={{
                  background: `url(${bgcta}) left/cover no-repeat`,
                }}
                className="w-full h-full"
              ></div>
            </div>
            <div className="relative flex items-start justify-between w-full mx-24 smd:mx-12">
              <img
                src={reachusillustration}
                alt="character"
                className="absolute bottom-0 right-0 smd:hidden"
              />
              <div className="flex flex-col justify-center gap-4">
                <h1 className={`${styles.headingLM} text-white`}>
                  <Trans i18nKey="landing.findout.title">
                    Cari tahu lebih dalam <br /> mengenai UNIVERAL
                  </Trans>
                </h1>
                <Link to="/">
                  <Button
                    title={t("landing.findout.btn")}
                    customization="bg-white text-primary-40 hover:bg-neutral-20"
                  />
                </Link>
              </div>
              <div className="w-[266px] smd:hidden"></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default LandingPage;
