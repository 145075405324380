import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const SECRET_TOKEN = process.env.REACT_APP_API_SECRET_TOKEN;

const customAxios = axios.create({
  baseURL: BASE_URL,
});

customAxios.interceptors.request.use(
  async (config) => {
    config.headers["Content-Type"] =
      config.headers["Content-Type"] === null
        ? "application/json"
        : config.headers["Content-Type"];
      config.headers["Authorization"] = "Bearer " + SECRET_TOKEN;
    return config;
  },
  async (error) => {
    console.warn("axios Error:", error);
    return await Promise.reject(error);
  }
);

customAxios.interceptors.response.use(
  async (response) => {
    return response;
  },
  async function (e) {
    console.warn("axios Error:", e);
    return await Promise.reject(e);
  }
);

export default customAxios;
