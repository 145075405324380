import React from "react";
import { Navbar, Header, Footer, Accordion } from "../components";
import { useState } from "react";
import { faqAccordion } from "../constants";
import { styles } from "../styles";
import { useTranslation, Trans } from "react-i18next";

const FaqPage = () => {
  const { t } = useTranslation();
  const [accordions, setAccordion] = useState(faqAccordion);
  const toggleAccordion = (accordionkey) => {
    const updatedAccordions = accordions.map((accord) => {
      if (accord.key === accordionkey) {
        return { ...accord, isOpen: !accord.isOpen };
      } else {
        return { ...accord, isOpen: false };
      }
    });

    setAccordion(updatedAccordions);
  };

  return (
    <>
      <Navbar />
      <Header title="FAQ" />
      <main className={`${styles.paddingX} flex flex-col gap-6 py-10`}>
        <div>
          {accordions.map((accordion, index) => (
            <Accordion
              key={accordion.key}
              title={t(`faq.${accordion.key}.title`)}
              data={t(`faq.${accordion.key}.data`)}
              isOpen={accordion.isOpen}
              list={index === 2 ? t("faq.3.list") : []}
              toggleAccordion={() => toggleAccordion(accordion.key)}
            />
          ))}
        </div>
      </main>
      <Footer />
    </>
  );
};

export default FaqPage;
