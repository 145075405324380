import { Footer, Header, Navbar, Button } from "../components";
import { lowonganKerja } from "../constants";
import { styles } from "../styles";
import { FaChevronRight } from "react-icons/fa";
import { useTranslation, Trans } from "react-i18next";

const LowonganKerjaPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      <Header title={t("job.header")} />

      <main
        className={`${styles.paddingX} flex flex-col items-center smd:flex-col gap-12 py-10`}
      >
        <div className="min-w-[600px] smd:min-w-full flex flex-col px-6 py-12 gap-4 h-full bg-[#EFEFEF] rounded-[8px]">
          <p className="font-bold">
            <Trans i18nKey="job.warning">WASPADA PENIPUAN!</Trans>
          </p>
          <p>
            <Trans i18nKey="job.desc">
              Dalam proses perekrutan calon karyawan,{" "}
              <b className="text-primary-40">UNIVERAL</b> tidak pernah meminta
              biaya apa pun, baik secara langsung maupun melalui pihak ketiga.
              Setiap calon karyawan yang menerima penawaran kerja dari{" "}
              <b className="text-primary-40">UNIVERAL</b> akan menjalani proses
              perekrutan resmi yang dilakukan oleh proyek atau pihak yang
              membutuhkan. Kami ingin mengingatkan para pencari kerja agar
              selalu berhati-hati dalam proses perekrutan ini dan teliti data
              serta informasi yang diberikan dalam proses tersebut.
            </Trans>
          </p>
        </div>

        <div className="flex flex-col items-center gap-6 min-w-[600px] smd:min-w-full w-full">
          {lowonganKerja.map((items, index) => (
            <div className="flex w-full max-h-full gap-12 px-6 py-6 bg-[#EFEFEF] rounded-[8px] items-center justify-start">
              <div className="flex relative w-[120px]">
                <img src={items.img} alt={items.title} />
              </div>
              <div className="flex flex-col gap-2 ">
                <h1 className={`${styles.headingM} font-bold"`}>
                  <Trans i18nKey={`job.details.${index}.title`}>
                    {items.title}
                  </Trans>
                </h1>
                <p className={`${styles.textLMediumM}`}>
                  <Trans i18nKey={`job.details.${index}.data`}>
                    {items.data}
                  </Trans>
                </p>
                <div className="">
                  <Button
                    title="Detail"
                    icon={<FaChevronRight />}
                    bg="bg-primary-40"
                    text="text-warning-40"
                    customization="text-warning-40 border-solid border border-[#920E34]"
                    type="submit"
                    onClick={(evt) => evt.preventDefault()}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </main>
      <Footer />
    </>
  );
};

export default LowonganKerjaPage;
