const styles = {
  paddingX: "px-36 md:px-16 sm:px-6",
  paddingY: "sm:py-16 py-6",
  padding: "sm:px-16 px-6 sm:py-16 py-10",
  paddingArticle: "px-36 md:px-16 sm:px-6",

  textSRegular: "text-[12px] leading-4 font-normal",
  textSMedium: "text-[12px] leading-4 font-medium",
  textMRegular: "text-[14px] leading-5 font-normal",
  textMMedium: "text-[14px] leading-5 font-medium",
  textLRegular: "text-[16px] leading-6 font-normal",
  textLRegularM: "text-[16px] leading-6 font-normal sm:text-[14px] sm:leading-5 sm:font-normal",
  textLMedium: "text-[16px] leading-6 font-medium",
  textLMediumM: "text-[16px] leading-6 font-medium sm:text-[14px] sm:leading-5 sm:font-medium",
  textXLRegular: "text-[18px] leading-7 font-normal",
  textXLMedium: "text-[18px] leading-7 font-medium",

  headingS: "text-[20px] leading-7 font-medium",
  headingSM: "text-[20px] leading-7 font-medium sm:text-[16px] sm:leading-6 sm:font-medium",
  headingM: "text-[28px] leading-9 font-medium",
  headingMS: "text-[28px] leading-9 font-medium sm:text-[20px] sm:leading-7",
  headingL: "text-[36px] leading-11 font-medium",
  headingLM: "text-[36px] leading-11 font-medium sm:text-[28px] sm:leading-9",
}

export { styles }