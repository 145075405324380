import { Navbar, Header, Footer } from "../components";
import { facilities } from "../constants";
import { styles } from "../styles";
import { useTranslation, Trans } from "react-i18next";

const FasilitasPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      <Header title={t("facility.header")} />
      <main
        className={`${styles.paddingX} flex flex-col items-center gap-12 py-10`}
      >
        <h2 className={`${styles.headingM} text-center`}>
          <Trans i18nKey="facility.title">
            Fasilitas-Fasilitas di Universitas Almarisah Madani
          </Trans>
        </h2>
        <p>
          <Trans i18nKey="facility.subtitle">
            Untuk menunjang serta mewujudkan visi dan misi kampus, Universitas
            Almarisah Madani menyediakan fasilitas-fasilitas yang dapat membantu
            mencapai efisiensi dan efektivitas dalam pengelolaan sumber daya.
            Fasilitas-fasilitas tersebut diantaranya:
          </Trans>
        </p>
        <section className="flex flex-wrap gap-16 smd:gap-6 justify-center w-full max-w-[1150px]">
          {facilities
            .filter((fac) => fac.title !== "vid")
            .map((fac) => {
              return (
                <div
                  style={{
                    background: `url(${fac.img}) center/cover no-repeat`,
                  }}
                  className="w-[380px] h-[238px] rounded-[10px] overflow-hidden relative"
                >
                  {/* <p className={`${styles.textLRegular} w-full bg-[#00000050] text-white font-semibold text-center absolute bottom-0 py-1`}>{fac.title}</p> */}
                </div>
              );
            })}
        </section>
      </main>

      <Footer />
    </>
  );
};

export default FasilitasPage;
